import React from 'react'

interface IModalUiProps {
  children?: React.ReactNode
}

interface IModalUiReturn {
  UiImgModal: React.FC<IModalUiProps>
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const UseImageUploadUi = (): IModalUiReturn => {
  const [isOpen, setIsOpen] = React.useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const UiImgModal: React.FC<IModalUiProps> = (props) => (
    <div
      className={`fixed inset-0 z-[999] flex items-center justify-center p-8 ${isOpen ? 'block' : 'hidden'}`}
    >
      <div
        className='absolute inset-0 bg-black bg-opacity-40 backdrop-blur-sm'
        onClick={onClose}
      />
      <div className='relative max-h-[600px] w-auto overflow-y-auto rounded-lg bg-esforce-black-350 p-5 text-center shadow-md'>
        {props.children}
      </div>
    </div>
  )

  return { UiImgModal, isOpen, onOpen, onClose }
}

import fimg1 from '@/assets/profile/female-1.png'
import fimg10 from '@/assets/profile/female-10.png'
import fimg11 from '@/assets/profile/female-11.png'
import fimg12 from '@/assets/profile/female-12.png'
import fimg13 from '@/assets/profile/female-13.png'
import fimg2 from '@/assets/profile/female-2.png'
import fimg3 from '@/assets/profile/female-3.png'
import fimg4 from '@/assets/profile/female-4.png'
import fimg5 from '@/assets/profile/female-5.png'
import fimg6 from '@/assets/profile/female-6.png'
import fimg7 from '@/assets/profile/female-7.png'
import fimg8 from '@/assets/profile/female-8.png'
import fimg9 from '@/assets/profile/female-9.png'
import img1 from '@/assets/profile/male-1.png'
import img10 from '@/assets/profile/male-10.png'
import img11 from '@/assets/profile/male-11.png'
import img12 from '@/assets/profile/male-12.png'
import img13 from '@/assets/profile/male-13.png'
import img2 from '@/assets/profile/male-2.png'
import img3 from '@/assets/profile/male-3.png'
import img4 from '@/assets/profile/male-4.png'
import img5 from '@/assets/profile/male-5.png'
import img6 from '@/assets/profile/male-6.png'
import img7 from '@/assets/profile/male-7.png'
import img8 from '@/assets/profile/male-8.png'
import img9 from '@/assets/profile/male-9.png'

export const maleProfile = [
  { id: '1', url: img1 },
  { id: '2', url: img2 },
  { id: '3', url: img3 },
  { id: '4', url: img4 },
  { id: '5', url: img5 },
  { id: '6', url: img6 },
  { id: '7', url: img7 },
  { id: '8', url: img8 },
  { id: '9', url: img9 },
  { id: '10', url: img10 },
  { id: '11', url: img11 },
  { id: '12', url: img12 },
  { id: '13', url: img13 },
]

export const femaleProfile = [
  { id: '1', url: fimg1 },
  { id: '2', url: fimg2 },
  { id: '3', url: fimg3 },
  { id: '4', url: fimg4 },
  { id: '5', url: fimg5 },
  { id: '6', url: fimg6 },
  { id: '7', url: fimg7 },
  { id: '8', url: fimg8 },
  { id: '9', url: fimg9 },
  { id: '10', url: fimg10 },
  { id: '11', url: fimg11 },
  { id: '12', url: fimg12 },
  { id: '13', url: fimg13 },
]
